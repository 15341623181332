import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import AccessDenied from '../AccessDenied';
import AuthWrapper from '../AuthWrapper';

const PrivateRoute = ({ children, user, isAuthenticated }) => {
  if (isAuthenticated) {
    if (user?.permissions?.includes('ACCESS_DASHBOARD')) {
      return children;
    } else {
      return <AccessDenied />;
    }
  } else {
    return <Navigate to="/internal/login" />;
  }
};

export default PrivateRoute;
