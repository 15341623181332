import { createSlice } from '@reduxjs/toolkit';
const shareSlice = createSlice({
  name: 'share',
  initialState: {
    isShare: false,
    shareId : null,
    shareURL : null,
    error : null
  },
  reducers: {
    setIsShare: (state, action) => {
      state.isShare = true;
    },
    setShareId: (state, action) => {
        state.shareId = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },

    setShareURL: (state , action) =>{
      state.shareURL = action.payload;
    }
  },
});
export const { setIsShare  , setError , setShareId  , setShareURL} = shareSlice.actions;
export default shareSlice.reducer;