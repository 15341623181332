import React from 'react';

const AwardIcon = ({fillColor = '#FFCB05'}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="interface-award-trophy--reward-rating-trophy-social-award-media">
        <path
          id="Vector"
          d="M18.147 12C19.0878 11.8063 19.9634 11.259 20.647 10.4225C21.5133 9.36239 22 7.92451 22 6.42522V4.02781C22 3.73263 21.8061 3.47252 21.5231 3.38826C15.3093 1.53725 8.69068 1.53725 2.47677 3.38825C2.1939 3.47251 2 3.73263 2 4.02781L2.00003 6.42522C2.00003 7.92451 2.4867 9.36239 3.35299 10.4225C4.03275 11.2544 4.90254 11.8003 5.83749 11.9968"
          stroke={fillColor}
          strokeWidth="1.35"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path id="Vector_2" d="M12 17V22" stroke={fillColor} strokeWidth="1.35" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector_3" d="M8 22H16" stroke={fillColor} strokeWidth="1.35" strokeLinecap="round" strokeLinejoin="round" />
        <path
          id="Vector_4"
          d="M6.03033 11.2638C6.13425 14.2925 8.71998 17 12 17C15.3377 17 17.8518 14.3402 17.9667 11.2585C17.9883 10.6783 18 10.0922 18 9.50003C18 7.09876 17.8266 4.65306 17.5428 2.48393C15.7602 2.11627 13.927 2 12 2C10.0729 2 8.20273 2.10215 6.45709 2.48393C6.15802 4.64393 6 7.09876 6 9.50003C6 10.0942 6.01036 10.6821 6.03033 11.2638Z"
          stroke={fillColor}
          strokeWidth="1.35"
        />
      </g>
    </svg>
  );
};

export default AwardIcon;
