import { createSlice } from '@reduxjs/toolkit';

const teamSlice = createSlice({
  name: 'team',
  initialState: {
    isTeamCreated: false,
    isPlayerAdded: false,
    error: null,
  },
  reducers: {
    setIsTeamCreated: (state, action) => {
      state.isTeamCreated = true;
    },

    setIsPlayerAdded: (state, action) => {
      state.isPlayerAdded = true;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },

  },
});

export const { setIsTeamCreated,  setIsPlayerAdded, setError} = teamSlice.actions;
export default teamSlice.reducer;
