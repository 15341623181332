import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Utility function to determine the position based on the screen widthconst getResponsivePosition = () => 'top-center';
const getResponsivePosition = () => 'top-center';

export const successnotify = (message) =>
  toast.success(message, {
    position: getResponsivePosition(), // Always top-center
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    draggable: true,
    progress: undefined,
  });

export const errornotify = (message) =>
  toast.error(message, {
    position: getResponsivePosition(), // Always top-center
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    draggable: true,
    progress: undefined,
  });