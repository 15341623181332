import React from 'react';

const BatIcon = ({fillColor}) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="v6-icon">
      <path
        id="icon"
        d="M19.1434 11.0532C19.1434 10.5376 18.9289 9.979 18.5001 9.59229L17.042 8.08838L23.0458 2.07275C23.1745 1.94385 23.2603 1.77197 23.2603 1.55713C23.2603 1.21338 22.9601 0.869629 22.5741 0.869629C22.3597 0.869629 22.1881 0.955566 22.0595 1.08447L16.0557 7.1001L14.5547 5.63916C14.1688 5.20947 13.6113 5.0376 13.0967 5.0376C12.582 5.0376 12.0246 5.20947 11.6386 5.63916C1.04616 16.2095 1.26058 15.7368 1.26058 16.6392C1.30346 21.2368 6.10651 22.8696 7.52169 22.8696C7.90765 22.8696 8.3365 22.6978 8.67957 22.397L18.5001 12.5142C18.9289 12.1274 19.1434 11.5688 19.1434 11.0532ZM7.69323 21.4087C7.60746 21.4946 7.56458 21.4946 7.56458 21.5376C5.80632 21.4087 2.67576 19.7759 2.67576 16.6392C2.67576 16.5962 2.67576 16.5532 2.76153 16.4673L9.53726 9.67822V13.2446C9.53726 14.0181 10.1376 14.6196 10.9096 14.6196H14.469L7.69323 21.4087ZM17.5566 11.5259L15.8413 13.2446H10.9096V8.30322L12.6249 6.58447C12.7536 6.45557 12.9251 6.36963 13.0967 6.36963C13.2682 6.36963 13.4397 6.45557 13.6113 6.58447L17.5566 10.5376C17.7282 10.7524 17.7711 10.9243 17.7711 11.0532C17.7711 11.2251 17.6853 11.397 17.5566 11.5259Z"
        fill={fillColor}
      />
      <path
        id="icon_2"
        d="M18.7603 20.3696C18.7603 21.4957 17.8632 22.3696 16.7603 22.3696C15.6301 22.3696 14.7603 21.4997 14.7603 20.3696C14.7603 19.2667 15.6342 18.3696 16.7603 18.3696C17.8591 18.3696 18.7603 19.2708 18.7603 20.3696Z"
        stroke={fillColor}
      />
    </g>
  </svg>
);

export default BatIcon;
