import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';

const OverTicker = ({ overData }) => {
  const [currentOver, setCurrentOver] = useState('0.0');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [activeBall, setActiveBall] = useState(null);

  useEffect(() => {
    if (overData?.length > 0) {
      setCurrentOver(overData[overData.length - 1].overNumber);
    }
  }, [overData]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.grid-item')) {
        setActiveBall(null);
      }
    };
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleBallClick = (index) => {
    setActiveBall(activeBall === index ? null : index);
  };

  const boxStyle = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: isMobile ? '0px' : '2px',
  };

  const innerBoxStyle = {
    width: 'auto',
    height: '31px',
    padding: '4px 8px',
    gap: '4px',
    borderRadius: '6px 6px 6px 6px',
    border: '1px solid #5649DF',
    background: '#5649DF1A',
  };

  const circleBoxStyle = {
    width: '30px',
    height: '29px',
    borderRadius: '50%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0px',
    position: 'relative',
  };

  const overlayBoxStyle = {
    position: 'absolute',
    top: '-40px',
    left: '50%',
    transform: 'translateX(-50%)',
    padding: '4px 8px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #5649DF',
    borderRadius: '4px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    zIndex: 10,
  };

  const processBallData = (ballData) => {
    let runs = ballData.run || '';
    if (ballData.isWide) {
      runs = `WD ${ballData.wideExtraRuns || ''}`;
      if (ballData.run.includes('W')) {
        runs = `${runs.trim()}`;
      }
    }
    if (ballData.isNoBall) {
      runs = `NB ${ballData.noBallExtraRuns || ''}`;
      if (ballData.run.includes('W')) {
        runs = `W${runs.trim()}`;
      }
    }
    if (ballData.isBye === 1) {
      runs += ' B';
    }
    return { ...ballData, color: ballData?.color || '#E2E8F0', run: runs };
  };

  const updatedOverData = overData?.map((over) => ({
    ...over,
    balls: over.balls.map(processBallData),
  }));

  return (
    <Box
      sx={{
        height: '40px',
        padding: '2px 0px',
        gap: '10px',
        bgcolor: 'linear-gradient(180deg, rgba(235, 234, 252, 0.52) 0%, rgba(235, 234, 252, 0) 100%)',
        border: 'none',
        borderRadius: '8px',
        overflowX: 'auto',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        '@media (min-width: 601px)': {
          overflowX: 'visible',
        },
        '@media (max-width: 600px)': {
          p: 0.5,
          maxWidth: '400px',
          fontSize: '60%',
          width: '100%',
        },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', p: 0 }}>
        <Box
          sx={{
            position: 'sticky',
            left: 0,
            zIndex: 1,
            background: '#fff',
            padding: '0px 5px 0px 0px',
          }}
        >
          <div style={boxStyle}>
            <div style={innerBoxStyle}>
              <Typography
                sx={{
                  fontSize: '10px',
                  fontWeight: 400,
                  lineHeight: '10px',
                  textAlign: 'center',
                  color: '#5649DF',
                }}
              >
                OVER
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 600,
                  lineHeight: '12px',
                  textAlign: 'center',
                  color: '#5649DF',
                }}
              >
                {currentOver}
              </Typography>
            </div>
          </div>
        </Box>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="flex-start"
          sx={{
            flexDirection: 'row',
            flexWrap: 'nowrap',
            maxWidth: '100%',
            overflowX: 'auto',
            '&::-webkit-scrollbar': {
              height: '0px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'grey',
              borderRadius: '0px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#FFFFFF',
            },
          }}
        >
          {updatedOverData.map((over) =>
            over.balls.map((ballData, index) => (
              <Grid
                item
                key={index}
                className="grid-item"
                onClick={(e) => {
                  e.stopPropagation();
                  handleBallClick(index);
                }}
              >
                <div style={{ ...circleBoxStyle, backgroundColor: ballData.color }}>
                  <Typography
                    sx={{
                      fontSize: '10px',
                      fontWeight: 600,
                      lineHeight: '10px',
                      textAlign: 'center',
                      color: ballData?.color === '#DF513D' || ballData?.color === '#0000FF' ? '#FFFFFF' : '#000000',
                    }}
                  >
                    
                    {ballData.run === 'W'
                      ? ballData.outType
                      : ballData.run.includes('W')
                      ? `RO${(ballData.wideExtraRuns || ballData.noBallExtraRuns || 0) + 1}`
                      : ballData.run}
                  </Typography>
                  {activeBall === index && (
                    <Box sx={overlayBoxStyle}>
                      <Typography
                        sx={{
                          fontSize: '12px',
                          fontWeight: 600,
                          lineHeight: '12px',
                          textAlign: 'center',
                          color: '#5649DF',
                        }}
                      >
                        strikername
                      </Typography>
                    </Box>
                  )}
                </div>
              </Grid>
            ))
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default OverTicker;
