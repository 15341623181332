import React from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

function AddButton() {

  
  return (
    <Button
      variant="contained" // You can also use 'outlined' or 'text' variants
      sx={{
        minWidth: '35px', // Setting the minimum width to make it circular
        color:'#FFFFFF', //
        height: '35px', // Same height to ensure it's a circle
        borderRadius: '50%', // Fully round border
        padding: '6.59px', // Remove any extra padding
        backgroundColor: '#B59000', // Customize your button color
        '&:hover': {
          backgroundColor: 'B59000', // Darken on hover
        },
      }}
    >
      <AddIcon />
    </Button>
  );
}

export default AddButton;
