import React from 'react';
import { Drawer, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const BottomSheet = ({ children, open, setOpen, closeHandler }) => {
  const handleDrawerClose = (event) => {
  
    setOpen(false);
    event.stopPropagation();
    if (closeHandler) {
      closeHandler(event);
    }
  };

  return (

    <Drawer
      anchor="bottom"
      open={open}
      onClose={(event) => handleDrawerClose(event)}
      ModalProps={{
        keepMounted: true,
        onBackdropClick: handleDrawerClose,
      }}
      sx={{
        '& .MuiDrawer-paper': {
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          padding: '10px',
          height: '176px',
          maxHeight: '80vh',
          width: 'auto',
          maxWidth: '100vw',
        },
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <IconButton onClick={handleDrawerClose} sx={{ padding: '0' }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ height: '5px', padding: '30px' }}>{children}</Box>
      </Box>
    </Drawer>
  );
};

export default BottomSheet;
