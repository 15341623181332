import { createSlice } from '@reduxjs/toolkit';

const tournamentSlice = createSlice({
  name: 'match',
  initialState: {
    data: null,
    loading: false,
    error: null,
    totalTournaments: '',
    tournamentlist: [],
    Alltournamentlist: [],
    tournamentMatches: [],
    getAllMatches: [],
    tournamentStats: null,
    leagueTable: [],
    ungroupedteams: [],
    groupedteamsLeagueTable: [],
    tournamentMatches_currentPage: 0,
    tournamentMatches_pageSize: 25,
    tournament_totalMatches: null,
    tournamentDetails: null,
    currentPage: 1,
    date: null,
  },
  reducers: {
    toggleTournamentLoading: (state) => {
      state.loading = !state.loading;
    },
    setTournamentError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setTournamentMatches: (state, action) => {
      state.tournamentMatches = action.payload.data.tournamentMatchDetails;
      state.tournament_totalMatches = action.payload.data.matchPaginationInfo.totalMatches;
      state.tournamentDetails = action.payload.data.tournamentDetails;
      state.getAllMatches = [...state.getAllMatches, ...action.payload.data.tournamentMatchDetails];
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload.data.matchPaginationInfo.currentPage;
    },
    setLeagueTable: (state, action) => {
      state.leagueTable = action.payload;
    },
    setUngroupedteams: (state, action) => {
      state.ungroupedteams = action.payload;
    },
    setGroupedteamsLeagueTable: (state, action) => {
      state.groupedteamsLeagueTable = [];
      for (const group in action.payload) {
        state.groupedteamsLeagueTable.push({
          leagueTable: action.payload[group]?.leagueTable || [],
          groupDisplayName: action.payload[group]?.meta?.displayName || '',
        });
      }
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setSelectedDate: (state, action) => {
      state.date = action.payload ?? null;
    },
    setTotalTournaments: (state, action) => {
      state.tournamentlist = action.payload.tournaments;
      state.Alltournamentlist = [...state.Alltournamentlist, ...action.payload.tournaments];
      state.totalTournaments = action.payload.totalTournaments;
    },
    clearTournaments: (state, action) => {
      state.Alltournamentlist = [];
    },
    clearGetAllMatches: (state, action) => {
      state.getAllMatches = [];
    },
    fetchTournamentMatches: (state, action) => {
      state.getAllMatches = [...state.getAllMatches];
    },
  },
});

export const {
  toggleTournamentLoading,
  setMatchError,
  setTournamentMatches,
  setCurrentPage,
  setLeagueTable,
  setUngroupedteams,
  setGroupedteamsLeagueTable,
  setTournamentError,
  setSelectedDate,
  setTotalTournaments,
  clearGetAllMatches,
  clearTournaments,
  fetchTournamentMatches,
} = tournamentSlice.actions;
export default tournamentSlice.reducer;