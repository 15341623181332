
import {  setError , setShareId } from './share.slice';
import api from '../../api/api';
import axios from 'axios';

export const generateShareId = (reqPayload) => {
    return async (dispatch) => {
      try {
        const response = await api.post(`${process.env.REACT_APP_API_BASE_URL}api/v0/share`, reqPayload);
        dispatch(setShareId(response.data.data.shareId));
      } catch (error) {
        dispatch(setError(error?.response?.message || error?.message));
        console.log("generetaedshareID error" , error);
      }
    };
  };